import React from 'react'

import { FaBars, FaSignInAlt, FaTimes } from 'react-icons/fa'
import { APP_CONFIG } from '../../../constants'
import Logo from '../assets/images/logo-preto-2.png'

export interface NavbarProps {}

export const Navbar: React.FC<NavbarProps> = (props) => 
{
    return (
        <>
            <div className="app-navbar">
                <div className="app-navbar-container">
                    <div className="app-navbar-content">
                        <div className="app-navbar-logo">
                            <a href="/"><img src={Logo} alt="Logo"/></a>
                        </div>
                        <div className="app-navbar-separate mobile-flex">
                            <div className="app-navbar-data">
                                <div className="app-navbar-data-item" onClick={() => {
                                    let app_navbar_mobile = document.getElementById('app-navbar-mobile')

                                    if(app_navbar_mobile)
                                    {
                                        app_navbar_mobile.classList.toggle('show')

                                        console.log('show')
                                    }
                                }}><FaBars/></div>
                            </div>
                        </div>
                        <div className="app-navbar-separate desktop-flex">
                            <div className="app-navbar-data">
                                <a className="app-navbar-data-item" href="/#planos">Planos & Preços</a>
                                <a className="app-navbar-data-item" href="/perguntas-frequentes">Perguntas e respostas</a>
                                <a className="app-navbar-data-item" href="/#sobre">Sobre</a>
                            </div>
                            
                            <div className="app-navbar-right-content">
                                <a className="app-navbar-data-item" rel="noopener noreferrer" target="_blank" href={`${APP_CONFIG.localhost}`}><FaSignInAlt/>Entrar</a>
                            </div>
                        </div>
                        
                        <div className="app-navbar-mobile" id="app-navbar-mobile">
                            <span className="close" onClick={() => {
                                let app_navbar_mobile = document.getElementById('app-navbar-mobile')

                                if(app_navbar_mobile)
                                {
                                    app_navbar_mobile.classList.toggle('show')
                                }
                            }}><FaTimes/></span>
                            <a href="/"><img width="150px"  src={Logo} alt="Logo"/></a>
                            <a className="item" href="/#planos">Planos & Preço</a>
                            <a className="item" href="/perguntas-frequentes">Perguntas e respostas</a>
                            <a className="item" href="/#sobre">Sobre</a>
                            <a className="item" rel="noopener noreferrer" target="_blank" href={`${APP_CONFIG.localhost}`}><FaSignInAlt/>Entrar</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}