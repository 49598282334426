import React, { useEffect, useRef, useState } from 'react'
import { formatValue } from 'react-currency-input-field'
import { useHistory } from 'react-router-dom'
import { PlansProps, RegisterAPI } from '../../services/Register/Register'
import { Navbar } from './components/Navbar'

import diagnostico_maturidade_gestao from './assets/diagnostico_maturidade_gestao.png'
import metodologia_propria from './assets/metodologia_propria.png'
import plataforma_gestao_completa from './assets/plataforma_gestao_completa.png'
import sistema_online from './assets/sistema_online.png'
import toda_gestao_sua_empresa_so_lugar from './assets/toda_gestao_sua_empresa_so_lugar.png'
import treinamento_100_pratico from './assets/treinamento_100_pratico.png'

import image_header_1 from './assets/images/image_header_1.jpg'

import logo_automacao from './assets/images/logo_automacao.png'
import logo_linked from './assets/images/logo_linked.png'
import logo_miki from './assets/images/logo_miki.png'

import image_1 from './assets/image_1.png'
import image_2 from './assets/image_2.png'
import image_3 from './assets/image_3.png'

import imageMarianaCamila from './assets/image/mariana_camila.png'

import imageMenAndWoman from './assets/image/image_1.jpg'
import imageWomanAndTablet from './assets/image/image_2.jpg'

import modum from './assets/images/modum.png'

import { APP_CONFIG } from '../../constants'
import { FaCheck, FaWhatsapp } from 'react-icons/fa'

export interface LadingPageProps {}

export const LadingPage: React.FC<LadingPageProps> = (props) => 
{
    const [plans, setPlans] = useState<Array<PlansProps>>([])
    const slideNumber = useRef(0)
    const history = useHistory()

    const showSlides = () => {
        var n = slideNumber.current;
        var i;
        var slides : any = document.getElementsByClassName("mySlides");

        for (i = 0; i < slides.length; i++) {
            slides[i].classList.remove('show')
        }

        n++;
        if (n > slides.length) {n = 1}

        slides[n-1].classList.add('show')

        slideNumber.current = n

        console.log(n)

        setTimeout(showSlides, 4000)
    }

    useEffect(() => {
        RegisterAPI.plans(setPlans)
        
        showSlides()
    }, [])
    

    return (
        <div className="lading-page">
            <Navbar />
            <div className="primary-header">
                <div className="image">
                    <img src={image_header_1} alt="" />
                </div>
                <div className="content">
                    <div className="container">
                        <h1>A METODOLOGIA DE GESTÃO COMPLETA <br /> PARA A SUA EMPRESA EM UM SÓ LUGAR.</h1>
                        <p>Cresça os resultados do seu negócio implementando uma <br /> metodologia 100% validada de forma prática <br /> sem precisar investir em consultorias que não geram resultados.</p>
                        <br />
                        <a className="btn-period-free" rel="noopener noreferrer" target="_blank" href={`${APP_CONFIG.localhost}/register`}>Experimente grátis</a>
                    </div>
                </div>
            </div>
            <div className="container">
                <hr />
                <div className="content-box-title">
                    <h2 id="planos">Planos para sua empresa começar a viver a Gestão Modum</h2>
                </div>
                <div className="plans">
                    {
                        plans.map((item, index) => {
                            let price = Number(item.price);

                            return (
                                <div key={`${index}`} className="plan_box col-12 col-mg-3 col-lg-3">
                                    <p className="title">{item.name}</p>
                                    <hr/>
                                    <ul>
                                        <li>Diagnóstico de maturidade de gestão</li>
                                        <li>Treinamento 100% prático</li>
                                        <li>Plataforma de gestão</li>
                                        <li>Suporte personalizado</li>
                                    </ul>
                                    <hr/>
                                    <p className="obs">*Quantidade de usuários: <b>{item.limit_people}</b></p>
                                    <br/>
                                    {
                                        item.price_with_discount === "0" ? (
                                            <p className="price-without-promotion">{formatValue({value: price.toString(), decimalScale: 2, intlConfig: { currency: 'BRL', locale: 'pt-BR'}})}</p>
                                        ) : (
                                            <>
                                                <p className="price">de <span className="value">{formatValue({value: item.price, decimalScale: 2, intlConfig: { currency: 'BRL', locale: 'pt-BR'}})}</span> por</p>
                                                <p className="price-with-promotion"><span className="value">{formatValue({value: item.price_with_discount, decimalScale: 2, intlConfig: { currency: 'BRL', locale: 'pt-BR'}})}</span> /mês</p>
                                                <p className="promotion-time">nos primeiros seis meses</p>
                                            </>
                                        )
                                    }
                                    <br />
                                    <button type="button" className="btn-next" onClick={() => {
                                        window.location.href = `${APP_CONFIG.localhost}/register`
                                    }}>
                                        7 dias grátis
                                    </button>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className="container">
                <div className="content-box-title">
                    <h2>O que você encontra no MODUM</h2>
                </div>
                <ul className="information-box row">
                    <li className="col-12 col-mg-4 col-lg-4">
                        <div>
                            <img src={diagnostico_maturidade_gestao} width="70px" alt="Diagnóstico de maturidade de gestão" />
                            <h4>Diagnóstico de maturidade de gestão</h4>
                        </div>
                    </li>
                    <li className="col-12 col-mg-4 col-lg-4">
                        <div>
                            <img src={treinamento_100_pratico} width="70px" alt="Treinamento 100% prático" />
                            <h4>Treinamento 100% prático</h4>
                        </div>
                    </li>
                    <li className="col-12 col-mg-4 col-lg-4">
                        <div>
                            <img src={plataforma_gestao_completa} width="70px" alt="Plataforma de gestão completa" />
                            <h4>Plataforma de gestão completa</h4>
                        </div>
                    </li>
                    <li className="col-12 col-mg-4 col-lg-4">
                        <div>
                            <img src={image_1} width="70px" alt="Diagnóstico de maturidade de gestão" />
                            <h4>Eleve o Crescimento da Sua Empresa para Outro Nível</h4>
                        </div>
                    </li>
                    <li className="col-12 col-mg-4 col-lg-4">
                        <div>
                            <img src={image_2} width="70px" alt="Treinamento 100% prático" />
                            <h4>Crie Uma Cultura Organizacional Focada Em Resultados</h4>
                        </div>
                    </li>
                    <li className="col-12 col-mg-4 col-lg-4">
                        <div>
                            <img src={image_3} width="70px" alt="Plataforma de gestão completa" />
                            <h4>Construa Um Time de Sucesso</h4>
                        </div>
                    </li>
                </ul>
            </div>
            <div className="bkg-blue-dark" style={{padding: '20px 0', marginTop: '30px'}}>
                <div className="container">
                    <div className="content-box-title">
                        <h2>Como funciona</h2>
                    </div>
                    <ul className="grid-itens row">
                        <li className="col-12 col-mg-4 col-lg-4">
                            <div>
                                <span className="item-number">1</span>
                                <h2>Faça o diagnóstico de maturidade de gestão do seu negócio</h2>
                                {/* <p>O segredo de toda implementação das boas práticas de gestão é entender em qual nível de maturidade seu negócio está. Ao entrar na plataforma, você poderá realizar o diagnóstico de sua empresa, de maneira personalizada e totalmente on-line. Assim, você entenderá por onde precisa começar!</p> */}
                            </div>
                        </li>
                        <li className="col-12 col-mg-4 col-lg-4">
                            <div>
                                <span className="item-number">2</span>
                                <h2>Estabeleça rotinas de gestão e acompanhamento de atividades individual e da equipe</h2>
                                {/* <p>Se você sente que está na maior parte do tempo apagando incêndios em vez de focar na parte estratégica do seu negócio, esse módulo vai te ajudar a implementar a rotina necessária com foco em resultados. <br /> <br /> Diárias / Semanais / Mensais Pautas e ATAS de reuniões automatizadas</p> */}
                            </div>
                        </li>
                        <li className="col-12 col-mg-4 col-lg-4">
                            <div>
                                <span className="item-number">3</span>
                                <h2>Estruture e reúna todos os processos da sua empresa</h2>
                                {/* <p>Chega de documentar os processos da sua empresa em pastas soltas no seu computador. No Modum, além de mapear todos os processos do seu negócio, você centraliza todos os documentos na própria plataforma de forma intuitiva e prática. <br /> <br /> Mapeamento dos processos por departamentos <br /> Procedimento Operacional Padrão (POP) <br /> Jornada do cliente</p> */}
                            </div>
                        </li>

                        <li className="col-12 col-mg-4 col-lg-4">
                            <div>
                                <span className="item-number">4</span>
                                <h2>Defina e organize o fluxo de gestão de pessoas do seu negócio</h2>
                                {/* <p>Construa um time de sucesso alinhado aos valores do seu negócio. No MODUM, você será capaz de adequar todo o fluxo de pessoas da sua empresa seguindo as melhores práticas de gestão de pessoas do Brasil. <br /> <br /> Manual do Colaborador <br /> Organograma <br /> Descrição de cargos <br /> Onboarding de colaboradores <br /> Processo de Feedback</p> */}
                            </div>
                        </li>
                        <li className="col-12 col-mg-4 col-lg-4">
                            <div>
                                <span className="item-number">5</span>
                                <h2>Controle o seu financeiro</h2>
                                {/* <p>Como anda a gestão financeira do seu negócio? Reúna todas as informações financeiras do seu negócio em um só lugar e tenha controle das despesas, lucro líquido e tome decisões com base nos resultados de forma assertiva. <br /> <br /> Plano de Contas <br /> Lançamento <br /> Previsão Orçamentária <br /> DRE <br /> Fluxo de Caixa </p> */}
                            </div>
                        </li>
                        <li className="col-12 col-mg-4 col-lg-4">
                            <div>
                                <span className="item-number">6</span>
                                <h2>Defina e acompanhe os indicadores de resultados por departamento</h2>
                                {/* <p>Todo negócio precisa ter bem claro os indicadores de resultados para estabelecer estratégias de crescimento. Aprenda como implementar os indicadores na sua empresa por área e acompanhe diretamente através da plataforma de maneira simples. <br /> <br /> Dashboard de Resultados intuito e prático <br /> Definição de indicadores de desempenho</p> */}
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="second-header">
                <div className="content-box-title">
                    <h2>PARA QUEM É</h2>
                </div>
                <div className="container">
                    <div className="content">
                        <div className="box-with-image-left">
                            <img src={imageMenAndWoman} width="450px" height="300px" alt="" />
                            <div className="text">
                                <p><span><FaCheck/></span>Para empreendedores com dificuldade em estabelecer rotinas e se sentem sobrecarregados com a administração do negócio.</p>
                                <br />
                                <p><span><FaCheck/></span>Para empreendedores que já investiram em consultorias de gestão e não conseguiram ter resultados práticos.</p>
                                <br />
                                <p><span><FaCheck/></span>Para donos de pequenas e médias empresas, que estão com o faturamento estagnado e querem fazer a empresa crescer.</p>
                                
                            </div>
                        </div>
                        <div className="box-with-image-right">
                            <div className="text">
                                <p><span><FaCheck/></span> Para empreendedores que desejam ter um negócio estruturado, que funcione de forma independente.</p>
                                <br />
                                <p><span><FaCheck/></span> Para empresários que buscam ter uma visão geral do negócio e tomar decisões baseadas em dados reais para obter mais resultados.</p>
                                <br />
                                <p><span><FaCheck/></span> Para empresários que buscam ter controle sob os seus resultados.</p>
                            </div>
                            <img src={imageWomanAndTablet} width="450px" height="300px" alt="" />
                        </div>
                    </div>
                </div> 
            </div>
            <div className="container">
                <div className="content-box-title" style={{margin: '30px 0'}}>
                    <h2>Porque escolher o Modum</h2>
                </div>
                <ul className="information-box row">
                    <li className="col-12 col-mg-4 col-lg-4">
                        <div>
                            <img src={metodologia_propria} width="70px" alt="Metodologia própria" />
                            <h4>Metodologia própria</h4>
                        </div>
                    </li>
                    <li className="col-12 col-mg-4 col-lg-4">
                        <div>
                            <img src={sistema_online} width="70px" alt="Sistema online" />
                            <h4>Sistema online</h4>
                        </div>
                    </li>
                    <li className="col-12 col-mg-4 col-lg-4">
                        <div>
                            <img src={toda_gestao_sua_empresa_so_lugar} width="70px" alt="toda a gestão da sua empresa em um só lugar" />
                            <h4>toda a gestão da sua empresa em um só lugar</h4>
                        </div>
                    </li>
                </ul>
            </div>
            <div className="bkg-blue-dark" style={{padding: '20px 0', marginTop: '30px'}}>
                <div className="container">
                    <div className="row align-center align-center-mobile">
                        <div className="col-12 col-mg-4 col-lg-4 desktop">
                            <img src={imageMarianaCamila} width="300px" alt="Mariana e Camila - Agência de Gestão" />
                        </div>
                        <div className="col-12 col-mg-8 col-lg-8 about">
                            <h2 id="sobre">Quem somos?</h2>
                            <br /><br />
                            <p>Olá, nós somos a Cami e a Mari, sócias e fundadoras da Agência de Gestão, uma consolidada empresa de consultoria que nasceu em Curitiba.</p>
                            <br /><br />
                            <p>Durante os 3 anos que estamos ativamente no mercado, atendemos empresas de 14 diferentes setores, levando de forma presencial a nossa própria metodologia, baseada em uma cultura de resultados. Agora, decidimos disseminá-la através do digital e por isso, chegamos até você!</p>
                            <br /><br />
                            <p>Nossa missão hoje é possibilitar ao maior número de empreendedores e empresários estratégias para gerir seus negócios com foco no crescimento sem se afundar na rotina operacional.</p>
                            <br />
                            <br /><br />
                            <h4 className="clr-white">“Insanidade é continuar fazendo sempre a mesma coisa e esperar resultados diferentes”</h4>
                            <p className="clr-blue-dark-light">Albert Einstein</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="content-box-title" style={{margin: '30px 0'}}>
                    <h2>DEPOIMENTOS</h2>
                    <h3>ESSES SÃO ALGUNS DOS CLIENTES QUE PASSARAM POR NOSSA METODOLOGIA DE GESTÃO</h3>
                </div>
                <div className="carousel-items">
                    <div className="mySlides">
                        <p>"Achei bastante completa pois atende todos os pilares e mais completa ainda porque tem a rotina para acompanhamento. É muito efetiva, principalmente na parte de resultados."</p>
                        <img src={logo_automacao} width="150px" alt="toda a gestão da sua empresa em um só lugar" />
                    </div>
                    <div className="mySlides">
                        <p>"Trabalhando sozinha ou não, percebi que tenho que aprender a gerenciar minha empresa. Por isso, o Modum caiu como uma luva. Ela vai da teoria à prática, então além de me capacitar, já consigo ir organizando os processos em paralelo. Hoje o Modum faz parte da estratégia de crescimento da MIKI Brands."</p>
                        <img src={logo_miki} width="150px" alt="toda a gestão da sua empresa em um só lugar" />
                    </div>
                    <div className="mySlides">                       
                        <p>"Quero parabenizar a AG pela iniciativa em criar um sistema tão completo, totalmente intuitivo e fácil de dominar em uma rotina. Se eu fosse destacar um ponto apenas é exatamente o fato de centralizar todas as ferramentas que uma empresa precisa dentro do MODUM! Eliminando vários outros apps da minha rotina."</p>
                        <img src={logo_linked} width="150px" alt="toda a gestão da sua empresa em um só lugar" />
                    </div>
                </div>
            </div>
            <div className="container" style={{textAlign: 'center', padding: '20px'}}>
                <img src={modum} alt="Logo Modum" />
            </div>
            <div className="btn-fixed-whatsapp" onClick={() => {
                window.open('https://api.whatsapp.com/send?phone=5541992830331&text=Olá gostaria de tirar algumas duvidas.', '_blank')
            }}>
                <FaWhatsapp />
            </div>
        </div>
    )
}