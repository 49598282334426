import { RouteProps } from "react-router-dom";
import { LadingPage } from "../pages/LandingPage";
import { LadingPageFAQ } from "../pages/LandingPageFAQ";

export const routes: (() => Array<RouteProps>) = () => 
{
    return [
        {
            path: "/",
            component: LadingPage,
            exact: true
        },
        {
            path: "/perguntas-frequentes",
            component: LadingPageFAQ,
            exact: true
        }
    ]
}

