import axios from 'axios'
import { APP_CONFIG } from '../../constants';

const Request = axios.create({
    baseURL: APP_CONFIG.server,
})  

Request.interceptors.request.use((config) => {
    return config
});

Request.interceptors.response.use(response => {
    return response;
}, error => {
    return Promise.reject(error.response)
});

export default Request;