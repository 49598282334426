import axios, { AxiosResponse } from "axios";
import Request from "../../functions/Request";

export interface RegisterFormProps {
    email: string
    company_name: string
    responsible_name: string
    fantasy: string
    cpf_cnpj: string
    phone: string
    password: string
    confirm_password: string
    cpf?: string
    birthday?: string
    account_created?: boolean
    cep?: string
    street?: string
    city?: string
    number?: string
    state?: string
    district?: string
}

export interface RegisteredFormProps {
    cpf: string
    birthday: string
}

export interface PlansProps {
    id: string
    name: string
    limit_people: string
    limit_gb: string
    price_with_discount: string
    price: string
}

export interface CardProps {
    card_number: string
    validate_month: string
    validate_year: string
    card_name: string
    card_security: string
    card_validated?: boolean
    cvv_size?: number
    brand?: string
    token?: string
}

const route = '/register'

class Register
{
    async validate(form: RegisterFormProps, success: ((response: AxiosResponse) => void), err: ((response: AxiosResponse) => void))
    {
        return await Request.post(`${route}/validate`, form).then(success).catch(err);
    }

    async register(form: RegisterFormProps, success: ((response: AxiosResponse) => void), err: ((response: AxiosResponse) => void))
    {
        return await Request.post(`${route}`, form).then(success).catch(err);
    }

    async plans(success: ((response: Array<PlansProps>) => void))
    {
        return await Request.get(`${route}/plans`).then(response => success(response.data.response));
    }

    async cep(cep: string, success: ((response: AxiosResponse) => void), err: ((response: AxiosResponse) => void))
    {
        return await axios.get(`https://viacep.com.br/ws/${cep}/json/`).then(success).catch(err);
    }
}

export const RegisterAPI = new Register()