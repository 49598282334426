export interface ISessionArgs {
    label: string
}

export const APP_CONFIG = {
    localhost: 'https://app.gestaomodum.com.br',
    server: 'https://server.gestaomodum.com.br',
    questions: [
        {
            title: 'Posso cancelar minha assinatura quando quiser?',
            response: 'Sim. Assinando o plano mensal você pode solicitar o cancelamento a qualquer momento.',
            view: false
        },
        {
            title: 'O Modum é indicada para qual tipo de empresa?',
            response: 'O Modum é indicada para empresas de pequeno e médio porte.',
            view: false
        },
        {
            title: 'Já contratei uma consultoria de gestão e não tive resultados, será que vale a pena?',
            response: 'Muitas vezes o processo de consultoria de gestão não funciona pelo fato do prazo de implementação do projeto. No Modum, por se tratar de uma plataforma online, todas as ações podem ser realizadas no seu tempo.',
            view: false
        },
        {
            title: 'Como funciona a forma de pagamento?',
            response: 'A assinatura pode ser contratada no plano mensal.',
            view: false
        },
        
        {
            title: 'Tem algum custo extra com instalação?',
            response: 'Não. Basta a confirmação da assinatura na plataforma e você já poderá desfrutar de todos os benefícios do Modum.',
            view: false
        },
        {
            title: 'Preciso de internet para usar a Modum?',
            response: 'Sim. O Modum é uma plataforma 100% online e precisa de internet para utilizá-la.',
            view: false
        },
        {
            title: 'Como funciona o treinamento de gestão?',
            response: 'O treinamento contém vídeos aulas objetivas, diretas e práticas, além dos exercícios, para você implementar todas as práticas de gestão na sua empresa e utilizar a plataforma para a gestão e acompanhamento.',
            view: false
        },
        {
            title: 'Minha empresa não tem todos os processos definidos, serve para mim?',
            response: 'Sim.  Principalmente para você. Se você não tem mapeado todos os processos da sua empresa, a plataforma MODUM vai te guiar com o passo a passo, através do nosso treinamento online.',
            view: false
        }
    ]
}
