import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { routes } from '../routes'
import '../assets/style.min.css'

export const Layout: React.FC = (props) => {
    return (
        <div className="app-layout">
            <BrowserRouter>
                <Switch>
                    {
                        routes().map((e, i) => <Route key={i} {...e} />)
                    }
                </Switch>
            </BrowserRouter>
        </div>
    )
}
