import React, { useState } from 'react'
import { Navbar } from './components/Navbar'

import image_header_1 from './assets/images/image_header_1.png'
import { APP_CONFIG } from '../../constants'

export interface LadingPageFAQProps {}

export const LadingPageFAQ: React.FC<LadingPageFAQProps> = (props) => 
{
    const [questions, setQuestions] = useState(APP_CONFIG.questions)

    return (
        <div className="lading-page">
            <Navbar />
            <div className="primary-header">
                <div className="image">
                    <img src={image_header_1} alt="" />
                </div>
                <div className="content">
                    <div className="container">
                        <h1>Tire suas dúvidas</h1>
                        <p>Cresça os resultados do seu negócio implementando uma <br /> metodologia 100% validada de forma prática <br /> sem precisar investir em consultorias que não geram resultados.</p>
                        <br />
                        <a className="btn-period-free" rel="noopener noreferrer" target="_blank" href={`${APP_CONFIG.localhost}/register`}>Experimente grátis</a>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="content-box-title">
                    <h2 id="question">Perguntas e respostas</h2>
                </div>
                <div className="content-box">
                    <div className="questions">
                        {
                            questions.map((item, index) => (
                                <div onClick={() => {
                                    let old = questions

                                    old[index].view = !item.view

                                    setQuestions([...old])
                                }} className={`${item.view ? `question show` : `question`}`} key={`${index}`}>
                                    <p>{item.title}</p>
                                    <div className="response">{item.response}</div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}